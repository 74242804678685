exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-edukacja-js": () => import("./../../../src/pages/edukacja.js" /* webpackChunkName: "component---src-pages-edukacja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-szkola-rodzenia-js": () => import("./../../../src/pages/szkola-rodzenia.js" /* webpackChunkName: "component---src-pages-szkola-rodzenia-js" */),
  "component---src-pages-zapisy-js": () => import("./../../../src/pages/zapisy.js" /* webpackChunkName: "component---src-pages-zapisy-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-offer-post-js": () => import("./../../../src/templates/offer-post.js" /* webpackChunkName: "component---src-templates-offer-post-js" */)
}

