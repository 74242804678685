/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({location, prevLocation}) => {
  function tryExecuteMainScript() {
    setTimeout(function () {
      if (window.MainScript) {
        if(document.querySelector('.header-main')) {
          document.querySelector('.header-main').classList.remove('expand')
        }
        if(document.querySelector('.circle')) {
          document.querySelector('.circle').classList.remove('expand')
        }
        if(document.querySelector('body')) {
          document.querySelector('body').classList.remove('overflow')
        }
        window.MainScript()
      } else {
        tryExecuteMainScript()
      }
    }, 10)
  }

  tryExecuteMainScript()
}
